import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_4/sub_11/slide2';
import SubSlider from './wrapperSubSlider';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "slide-4-11-8"}}) {
      body
    }
    file(relativePath: { eq: "chapter_4/sub_11/4-11-2-bg__mobile.jpg"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
    audioImageA: file(relativePath: { eq: "chapter_4/sub_11/4-11-8-audio.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }
  }
  `);
  return (
    <Template query={query} subSlider={<SubSlider />} />
  );
};


export default Slide;
